<template>
  <div class="bitelife-page">
    <!-- Hero -->
    <section class="hero-section">
      <div class="hero-section__inner">
        <div class="flex flex-col px-2 md:px-20">
          <div class="m-auto text-center md:text-left text-theme-dark">
            <small class="text-base font-medium text-red-700">BITELIFE</small>
            <h1 class="text-3xl md:text-6xl pt-2 font-bold">Meal planner for vegetarian and vegan</h1>
            <a href="https://apps.apple.com/it/app/bitelife/id1491657591?l=en" class="m-auto" target="_blank">
              <img alt="timerly main" class="m-auto md:ml-0 pt-4 md:pt-12 w-48" src="@/assets/app-store-download-black.svg">
            </a>
          </div>
        </div>
        <div class="flex fadeUp mt-8 md:mt-0" :class="{'show':showImages}">
          <img alt="timerly main" class="main-image" src="@/assets/portfolio/bitelife/myplan.webp">
        </div>
      </div>
    </section>
    <!-- IDEA -->
    <section class="flex w-full pt-48 pb-24 -mt-20 px-4 bg-white text-theme-dark">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl px-2 m-auto">
        <h3 class="text-center text-5xl md:text-6xl font-bold">The idea</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 py-8 gap-12">
          <div class="flex flex-col text-center md:text-left">
            <small class="text-red-700 font-medium">PROBLEM TO SOLVE</small>
            <span class="leading-7 text-lg pt-4">For people who are approaching the vegetarian or vegan diet or for those who already use these diets it can be very difficult to manage meals in order to ensure variety of dishes and above all the intake of nutrients needed daily.<br> Bitelife was born from the idea of suggesting to the user the best combinations of dishes based on their personal tastes and goals, whether you want to lose weight, maintain it or increase it.</span>
          </div>
          <div class="flex flex-col text-center md:text-left">
            <small class="text-red-700 font-medium">SOLUTION</small>
            <span class="leading-7 text-lg pt-4">The solution comes from the creation of a proprietary algorithm that, through a vast assortment of recipes (over 40000), is able to calculate your perfect meal plan considering the nutrients your body needs daily.<br> It is also possible to enter your allergies and habits to have an even more personalized meal plan and easily keep an eye on daily and long-term progress.</span>
          </div>
        </div>
      </div>
    </section>
    <!-- STACK -->
    <section class="flex w-full py-20 px-4 text-white">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl m-auto">
        <h3 class="text-center text-5xl md:text-6xl font-bold">Stack & Tools</h3>
        <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 py-8 gap-8 sm:gap-10 md:gap-16">
          <TechCard title="Swift" icon="swift" />
          <TechCard title="Laravel" icon="laravel" />
          <TechCard title="MySQL" icon="mysql" />
          <TechCard title="SwiftUI" icon="swift" />
          <TechCard title="Forge" icon="laravelforge" />
          <TechCard title="Sketch" icon="sketch" />
        </div>
      </div>
    </section>
    <!-- SCREENSHOT -->
    <section class="flex w-full py-20 bg-white text-theme-dark px-4">
      <div class="grid grid-cols-1 gap-6 max-w-screen-xl m-auto">
        <div class="text-center grid grid-cols-1 gap-4">
          <h3 class="text-5xl md:text-6xl font-bold">The Application</h3>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 py-8 pt-10">
          <img alt="bitelife progress" src="@/assets/portfolio/bitelife/progress.webp">
          <img alt="bitelife parameters" src="@/assets/portfolio/bitelife/parameters.webp">
          <img alt="bitelife recipe" src="@/assets/portfolio/bitelife/recipe.webp">
          <img alt="bitelife recipe" src="@/assets/portfolio/bitelife/habits.webp">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import TechCard from "@/components/card/TechCard";

export default {
  name: "bitelife",
  components: {TechCard},
  setup() {

    const showImages = ref(false)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showImages.value = true;
      }, 100);
    })

    return {
      showImages
    }
  }
}
</script>

<style lang="scss" scoped>

.bitelife-page {
  .hero-section {
    @apply flex w-full py-36 px-4 -mt-20;
    transform: skewY(-4deg);
    background-color: #AEF7D4;

    &__inner {
      @apply m-auto grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-xl;
      transform: skewY(4deg);
    }
  }

  .main-image {
    @apply m-auto -mb-48;
    max-height: 45rem;
  }
}

</style>